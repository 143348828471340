/* Import fonts */
@font-face {
  font-family: "SuisseIntl Web";
  src: url("./assets/fonts/SuisseIntl-Regular-WebM.eot");
  src: url("./assets/fonts/SuisseIntl-Regular-WebM.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SuisseIntl-Regular-WebM.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl-Regular-WebM.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: block;
}

@font-face {
  font-family: "SuisseIntl Web";
  src: url("./assets/fonts/SuisseIntl-Medium-WebM.eot");
  src: url("./assets/fonts/SuisseIntl-Medium-WebM.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SuisseIntl-Medium-WebM.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl-Medium-WebM.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: block;
}

@font-face {
  font-family: "SuisseIntl Web";
  src: url("./assets/fonts/SuisseIntl-SemiBold-WebM.eot");
  src: url("./assets/fonts/SuisseIntl-SemiBold-WebM.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SuisseIntl-SemiBold-WebM.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl-SemiBold-WebM.woff") format("woff");
  font-weight: 600 700;
  font-style: normal;
  font-stretch: normal;
  font-display: block;
}

/* Global styling */
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

html {
  font-family: "SuisseIntl Web", "Helvetica", Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  transition: background-color 400ms ease-in-out;
}

body {
  margin: 0;
  font-family: "SuisseIntl Web", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  transition: background-color 400ms ease-in-out;
}
